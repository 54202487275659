import React, {
  FormEvent,
  Fragment,
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from "react";
import { ImageUploadField } from "./ImageUploadField";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Snackbar,
  styled,
  Tooltip
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  Buy,
  CrawledAlbum,
  CreateAlbumRequest,
  FilterOptions,
  StreamingServices
} from "../../store/types";
import produce from "immer";
import DatePicker from "@mui/lab/DatePicker";
import Button from "@mui/material/Button";
import { Api } from "../../utils/apiClient";
import { getBase64ImageFromUrl } from "../../utils/imageDownloader";
import SearchIcon from "@mui/icons-material/Search";
import LaunchIcon from "@mui/icons-material/Launch";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import { add } from "date-fns";
import { isValidDate } from "../../utils/date";

type Props = {
  prefilledRelease: CreateAlbumRequest;
  crawledAlbum?: CrawledAlbum;
  onAlbumSubmit: () => void;
  onCancel?: () => void;
};

const Form = styled("form")``;
const Img = styled("img")``;

function replaceFastAppleMusicLink(streamingServiceLink: string | undefined) {
  if (streamingServiceLink) {
    return streamingServiceLink.replace("https://", "itmss://");
  }
  return streamingServiceLink;
}

function getSearchLink(
  newRelease: CreateAlbumRequest,
  searchLinkPrefix: string | string[],
  serviceName: keyof StreamingServices,
  focusRef?: MutableRefObject<HTMLInputElement | undefined>
) {
  function getSearchLinkButton(prefix: string) {
    return (
      <IconButton
        aria-label={`search on ${serviceName}`}
        href={`${prefix}${encodeURIComponent(
          newRelease.artist + " - " + newRelease.album
        )}`}
        rel="noopener noreferrer"
        target={`psyfeed_${serviceName}_search`}
        onClick={() => {
          if (focusRef && focusRef.current) {
            focusRef.current.focus();
          }
        }}
        size="large"
      >
        <SearchIcon />
      </IconButton>
    );
  }

  return (
    <>
      {newRelease.streamingServices[serviceName] &&
        serviceName === "appleMusic" && (
          <IconButton
            aria-label={`fast open ${serviceName}`}
            href={`${replaceFastAppleMusicLink(
              newRelease.streamingServices[serviceName]
            )}`}
            rel="noopener noreferrer"
            target={`psyfeed_${serviceName}`}
            size="large"
          >
            <OpenInBrowser />
          </IconButton>
        )}
      {newRelease.streamingServices[serviceName] && (
        <IconButton
          aria-label={`open ${serviceName}`}
          href={`${newRelease.streamingServices[serviceName]}`}
          rel="noopener noreferrer"
          target={`psyfeed_${serviceName}`}
          size="large"
        >
          <LaunchIcon />
        </IconButton>
      )}
      {typeof searchLinkPrefix === "string"
        ? getSearchLinkButton(searchLinkPrefix)
        : searchLinkPrefix.map((prefix, index) => (
            <Fragment key={index}>{getSearchLinkButton(prefix)}</Fragment>
          ))}
    </>
  );
}
export function createBuyLink(link: string): Buy {
  let title;
  if (link.toLowerCase().includes("beatport")) {
    title = "Beatport";
  } else if (link && link.toLowerCase().includes("psyshop")) {
    title = "Psyshop";
  } else if (link && link.toLowerCase().includes("bandcamp")) {
    title = "Bandcamp";
  } else {
    title = "Shop";
  }

  return {
    link,
    title
  };
}

type StreamingServiceFieldProps = {
  label: string;
  newRelease: CreateAlbumRequest;
  searchLinkPrefix: string | string[];
  serviceName: keyof StreamingServices;
  setNewRelease: (newRelease: CreateAlbumRequest) => void;
  endAdornment?: JSX.Element;
};
const StreamingServiceField: FunctionComponent<StreamingServiceFieldProps> = ({
  label,
  newRelease,
  searchLinkPrefix,
  serviceName,
  setNewRelease,
  endAdornment
}) => {
  const textFieldRef = useRef<HTMLInputElement>();
  return (
    <TextField
      multiline
      variant="outlined"
      margin="normal"
      required={false}
      fullWidth={true}
      id={serviceName}
      label={label}
      name={serviceName}
      value={newRelease.streamingServices[serviceName]}
      onChange={e => {
        setNewRelease(
          produce(newRelease, (draft: CreateAlbumRequest) => {
            draft.streamingServices[serviceName] = e.target.value;
          })
        );
      }}
      InputProps={{
        inputRef: textFieldRef,
        endAdornment: (
          <>
            {endAdornment}
            {getSearchLink(
              newRelease,
              searchLinkPrefix,
              serviceName,
              textFieldRef
            )}
          </>
        )
      }}
    />
  );
};

const NewAlbumForm: FunctionComponent<Props> = ({
  prefilledRelease,
  crawledAlbum,
  onAlbumSubmit,
  onCancel
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [artwork, setArtwork] = useState("");
  const [addedAtFromReleaseDate, setAddedAtFromReleaseDate] = useState(false);

  const [newRelease, setNewRelease] = useState<CreateAlbumRequest>(
    prefilledRelease
  );

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    labels: [],
    genres: [],
    totalAlbums: 0
  });

  useEffect(() => {
    const cancelTokenSource = Api.cancelToken();

    Api.fetchFilterOptions().then(value => {
      setFilterOptions(value);
    });

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    setNewRelease(
      produce(prefilledRelease, (draft: CreateAlbumRequest) => {
        if (crawledAlbum) {
          if (!draft.label) {
            draft.label = crawledAlbum.label;
          }

          if (!draft.genre) {
            draft.genre = crawledAlbum.genre;
          }

          if (!draft.buy || !draft.buy.link) {
            draft.buy = createBuyLink(crawledAlbum.url);
          }

          if (crawledAlbum.single) {
            draft.single = true;
          }
        }
      })
    );
  }, [crawledAlbum, prefilledRelease]);

  useEffect(() => {
    if (prefilledRelease.artwork) {
      try {
        getBase64ImageFromUrl(prefilledRelease.artwork).then(value => {
          setArtwork(value);
        });
      } catch (e) {
        setError("Could not download cover image");
        console.error("Could not download cover image", e);
      }
    }
  }, [prefilledRelease]);

  const inputInvalid = () =>
    !newRelease.artist ||
    !newRelease.album ||
    !newRelease.label ||
    !newRelease.genre ||
    !newRelease.releaseDate ||
    !artwork ||
    (!newRelease.streamingServices.appleMusic &&
      !newRelease.streamingServices.spotify &&
      !newRelease.streamingServices.tidal);

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (inputInvalid()) {
      setError("Not all required fields are filled");
    } else {
      setSubmitting(true);
      Api.submitNewAlbum(
        produce(newRelease, (draft: CreateAlbumRequest) => {
          draft.artwork = artwork;
          if (addedAtFromReleaseDate) {
            draft.addedAt = draft.releaseDate;
          } else {
            draft.addedAt = new Date();
          }
        })
      )
        .then(success => {
          if (success) {
            onAlbumSubmit();
            setArtwork("");
          } else {
            setError("Could not submit album");
            console.error("Could not submit album");
          }
        })
        .catch(error => {
          setError("Could not submit album");
          console.error("Could not submit album", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const buyLinkDiffersFromCrawled = () =>
    crawledAlbum &&
    crawledAlbum.url &&
    newRelease.buy &&
    newRelease.buy.link &&
    crawledAlbum.url !== newRelease.buy.link;

  return (
    <Form
      sx={{
        width: "100%", // Fix IE 11 issue.
        marginTop: "theme.spacing(1)"
      }}
      noValidate
      onSubmit={onFormSubmit}
    >
      <Snackbar
        open={!!error}
        autoHideDuration={20000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setError("");
        }}
      >
        <Alert
          onClose={() => {
            setError("");
          }}
          severity="error"
        >
          {error}
        </Alert>
      </Snackbar>
      <ImageUploadField
        title={"Album Cover"}
        onImageUpload={base64Image => {
          setArtwork(base64Image);
        }}
      />

      <Box
        mt={3}
        mb={1}
        sx={{
          width: 301,
          height: 301,
          outline: "1px dotted #fff",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        {artwork && (
          <Img
            sx={{
              maxWidth: 300,
              maxHeight: 300
            }}
            src={`${artwork}`}
            alt="uploaded cover"
          />
        )}
      </Box>

      <TextField
        multiline
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="artist"
        label="Artist"
        name="artist"
        autoComplete="artist"
        value={newRelease.artist}
        onChange={e => {
          setNewRelease(
            produce(newRelease, (draft: CreateAlbumRequest) => {
              draft.artist = e.target.value;
            })
          );
        }}
        InputProps={{
          startAdornment: (
            <IconButton
              aria-label={`search psyfeed link`}
              href={`https://psyfeed.org/?search=${encodeURIComponent(
                newRelease.artist
              )}`}
              rel="noopener noreferrer"
              target={`psyfeed_org`}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          ),
          endAdornment: (
            <>
              {crawledAlbum && (
                <Tooltip title={crawledAlbum.artist}>
                  <Button
                    onClick={() => {
                      setNewRelease(
                        produce(newRelease, (draft: CreateAlbumRequest) => {
                          draft.artist = crawledAlbum.artist;
                        })
                      );
                    }}
                    sx={{
                      mr: 1
                    }}
                  >
                    Crawled
                  </Button>
                </Tooltip>
              )}
              <Button
                onClick={() => {
                  setNewRelease(
                    produce(newRelease, (draft: CreateAlbumRequest) => {
                      draft.artist = "Various Artists";
                    })
                  );
                }}
              >
                VA
              </Button>
            </>
          )
        }}
      />
      <TextField
        multiline
        variant="outlined"
        margin="normal"
        required={true}
        fullWidth={true}
        id="album"
        label="Album"
        name="album"
        autoComplete="album"
        value={newRelease.album}
        onChange={e => {
          setNewRelease(
            produce(newRelease, (draft: CreateAlbumRequest) => {
              draft.album = e.target.value;
            })
          );
        }}
        InputProps={{
          startAdornment: (
            <IconButton
              aria-label={`search psyfeed link`}
              href={`https://psyfeed.org/?search=${encodeURIComponent(
                newRelease.album
              )}`}
              rel="noopener noreferrer"
              target={`psyfeed_org`}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          )
        }}
      />
      <Box mb={1} mt={1}>
        <Autocomplete
          id="label"
          options={filterOptions.labels}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              multiline
              label="Label"
              variant="outlined"
              required={true}
              fullWidth={true}
              name="label"
              InputProps={{
                startAdornment: (
                  <IconButton
                    aria-label={`search psyfeed link`}
                    href={`https://psyfeed.org/?labels=${encodeURIComponent(
                      newRelease.label || ""
                    )}`}
                    rel="noopener noreferrer"
                    target={`psyfeed_org`}
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                )
              }}
            />
          )}
          inputValue={newRelease.label}
          onInputChange={(e: any, value: string | null) => {
            setNewRelease(
              produce(newRelease, (draft: CreateAlbumRequest) => {
                draft.label = value || "";
              })
            );
          }}
        />
      </Box>
      <Box mb={1} mt={2}>
        <Autocomplete
          id="genre"
          options={filterOptions.genres}
          freeSolo
          renderInput={params => (
            <TextField
              {...params}
              multiline
              label="Genre"
              variant="outlined"
              required={true}
              fullWidth={true}
              name="genre"
            />
          )}
          inputValue={newRelease.genre}
          onInputChange={(e: any, value: string | null) => {
            setNewRelease(
              produce(newRelease, (draft: CreateAlbumRequest) => {
                draft.genre = value || "";
              })
            );
          }}
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={newRelease.single}
              onChange={(event, checked) => {
                setNewRelease(
                  produce(newRelease, (draft: CreateAlbumRequest) => {
                    draft.single = checked;
                  })
                );
              }}
              value="single"
              color="primary"
            />
          }
          label="Single"
        />
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={addedAtFromReleaseDate}
              onChange={(event, checked) => {
                setAddedAtFromReleaseDate(checked);
              }}
              value="addedAtFromRelease"
              color="primary"
            />
          }
          label="Hide in New"
        />
      </Box>
      <Box my={2}>
        <DatePicker
          label="ReleaseDate"
          mask={"__.__.____"}
          value={newRelease.releaseDate}
          onChange={date => {
            setNewRelease(
              produce(newRelease, (draft: CreateAlbumRequest) => {
                draft.releaseDate = date as Date;
              })
            );
          }}
          renderInput={params => <TextField {...params} />}
        />
      </Box>
      <Box>
        <Button
          variant="outlined"
          disabled={!newRelease.releaseDate}
          onClick={() => {
            setNewRelease(
              produce(newRelease, (draft: CreateAlbumRequest) => {
                if (!isValidDate(draft.releaseDate)) {
                  draft.releaseDate = new Date();
                }
                draft.releaseDate = add(draft.releaseDate!!, { days: -1 });
              })
            );
          }}
        >
          -1
        </Button>
        <Button
          sx={{
            marginX: 1
          }}
          variant="outlined"
          onClick={() => {
            setNewRelease(
              produce(newRelease, (draft: CreateAlbumRequest) => {
                draft.releaseDate = new Date();
              })
            );
          }}
        >
          Set Today
        </Button>
        <Button
          variant="outlined"
          disabled={!newRelease.releaseDate}
          onClick={() => {
            setNewRelease(
              produce(newRelease, (draft: CreateAlbumRequest) => {
                if (!isValidDate(draft.releaseDate)) {
                  draft.releaseDate = new Date();
                }
                draft.releaseDate = add(draft.releaseDate!!, { days: 1 });
              })
            );
          }}
        >
          +1
        </Button>
      </Box>
      <TextField
        multiline
        variant="outlined"
        margin="normal"
        required={false}
        fullWidth={true}
        id="buy"
        label="Shop Link"
        name="buy"
        value={newRelease.buy.link}
        onChange={e => {
          setNewRelease(
            produce(newRelease, (draft: CreateAlbumRequest) => {
              const link = e.target.value;
              draft.buy = createBuyLink(link);
            })
          );
        }}
        InputProps={{
          startAdornment: (
            <>
              {buyLinkDiffersFromCrawled() && (
                <ReportOutlinedIcon
                  color={"error"}
                  style={{ marginRight: 5 }}
                />
              )}
            </>
          ),
          endAdornment: (
            <>
              {buyLinkDiffersFromCrawled() && (
                <Tooltip title={crawledAlbum!!.url}>
                  <Button
                    onClick={() => {
                      setNewRelease(
                        produce(newRelease, (draft: CreateAlbumRequest) => {
                          draft.buy.link = crawledAlbum!!.url;
                        })
                      );
                    }}
                    sx={{
                      mr: 1
                    }}
                  >
                    Crawled
                  </Button>
                </Tooltip>
              )}
              {newRelease.buy && newRelease.buy.link && (
                <IconButton
                  aria-label={`search on ${newRelease.buy.title}`}
                  href={newRelease.buy.link}
                  rel="noopener noreferrer"
                  target={`psyfeed_${newRelease.buy.title}`}
                  size="large"
                >
                  <LaunchIcon />
                </IconButton>
              )}
              <IconButton
                aria-label={`search shop link`}
                href={`https://www.google.de/search?q=${encodeURIComponent(
                  newRelease.artist + " - " + newRelease.album
                )}`}
                rel="noopener noreferrer"
                target={`psyfeed_shop_search`}
                size="large"
              >
                <SearchIcon />
              </IconButton>
            </>
          )
        }}
      />
      <StreamingServiceField
        label="Apple Music Link"
        newRelease={newRelease}
        setNewRelease={setNewRelease}
        serviceName="appleMusic"
        searchLinkPrefix="https://music.apple.com/search?searchIn=am&term="
      />
      <StreamingServiceField
        label="Spotify Link"
        newRelease={newRelease}
        setNewRelease={setNewRelease}
        serviceName="spotify"
        searchLinkPrefix="https://open.spotify.com/search/"
      />
      <StreamingServiceField
        label="Tidal Link"
        newRelease={newRelease}
        setNewRelease={setNewRelease}
        serviceName="tidal"
        searchLinkPrefix="https://listen.tidal.com/search?q="
        endAdornment={
          <IconButton
            aria-label={`search shop link`}
            href={`https://www.google.de/search?q=${encodeURIComponent(
              `${newRelease.artist} - ${newRelease.album} tidal`
            )}`}
            rel="noopener noreferrer"
            target={`psyfeed_shop_search`}
            size="large"
          >
            <SearchIcon />
          </IconButton>
        }
      />
      <StreamingServiceField
        label="Youtube Link"
        newRelease={newRelease}
        setNewRelease={setNewRelease}
        serviceName="youtube"
        searchLinkPrefix={[
          "https://music.youtube.com/search?q=",
          "https://www.youtube.com/results?search_query="
        ]}
      />
      <StreamingServiceField
        label="Deezer Link"
        newRelease={newRelease}
        setNewRelease={setNewRelease}
        serviceName="deezer"
        searchLinkPrefix="https://www.deezer.com/search/"
      />
      {/*<StreamingServiceField*/}
      {/*  label="Amazon Music Link"*/}
      {/*  newRelease={newRelease}*/}
      {/*  setNewRelease={setNewRelease}*/}
      {/*  serviceName="amazonMusic"*/}
      {/*  searchLinkPrefix="https://music.amazon.de/search/"*/}
      {/*/>*/}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        sx={{
          mt: 2
        }}
        disabled={inputInvalid() || submitting}
      >
        Submit
      </Button>
      {onCancel && (
        <Button
          fullWidth
          variant="outlined"
          color="inherit"
          sx={{
            mt: 2
          }}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
      )}
    </Form>
  );
};

export { NewAlbumForm };
